<!--  -->
<template>
<div class="filePage">
    <div class="protocol-head">
        <div class="protocol-head-container flex-vc flex" >
            <a href="/" class="flex-vc flex">
                <div class="logo">
                    <img src="../../assets/img/default/upupoo.png">
                </div>
                <span class="text">upupoo</span>
            </a>
        </div>
    </div>
   <div class="protocol-layout-body">
    <h3>《UPUPOO激活码使用协议》</h3>
    <p class="protocal-title-bold">1 【协议的范围】</p>
    <p>如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称"监护人"）的陪同下阅读和判断是否同意本规则。</p>
    <p>如果您是中国大陆地区以外的用户，您订立或履行本规则的行为需要同时遵守您所属和/或所处国家或地区的法律。</p>
    <p class="protocal-title-bold">2 【什么是UPUPOO激活码？】</p>
    <p>UPUPOO激活码是针对UPUPOO的Windows客户端用户推出的一种虚拟商品，是一串由16位字母加数字随机组合而成的序列号码，只有通过序列号码激活绑定后，用户才可享受到该软件的全部功能，其中包括在线资源下载、上传、壁纸切换、个性组件服务等。</p>
    <p class="protocal-title-bold">3 【如何获取激活码？】</p>
    <p>3.1 官网购买：UPUPOO官网（upupoo.com）是激活码发放的唯一官方平台，其他获取方式均为合作或授权销售平台。</p>
    <p>3.1.1 激活码价格：以实时的官网价格信息为准。</p>
    <p>3.1.2 购买方式：1）登录UPUPOO官网激活码购买页面（cdkey.upupoo.com），选择激活码购买数量，无数量限制；2）填写激活码接受方式，如手机短信或电子邮件等；3）扫码支付订单，如微信扫码支付及支付宝扫码支付等；4）发货，后台将会以短信或邮件的形式，发送订单对应数量的激活码序列号。</p>
    <p>3.2 电商平台购买：如登录淘宝、京东等电商购物平台的直营店或授权销售店铺，价格以平台实时价格为准，购买方式以平台规则为准。</p>
    <p>3.3 活动奖励：可以通过参加UPUPOO组织的各类线上线下活动获取激活码，激活码将以虚拟奖品的方式进行发放，具体规则以活动规则为准。</p>
    <p>3.4 渠道获取：upupoo可能与其他渠道平台进行合作（如Steam、Origin、育碧等），用户可以选择通过渠道平台进行购买，购买规则以渠道平台规则为准。</p>
    <p class="protocal-title-bold">4 【激活码的使用】</p>
    <p>UPUPOO激活码仅供UPUPOO用户在Windows客户端进行账号绑定激活使用。</p>
    <p>激活流程：1）下载UPUPOO-Windows客户端；2）安装并启动应用程序；3）登录/注册账号；4）输入激活码，点击【立即验证】按钮；5）激活成功后，该激活码序列号立即失效，无法再次绑定激活。</p>
    <p>激活后可享受的完整软件功能包括但不限于：向UPUPOO Windows客户端上传壁纸、使用UPUPOO Windows客户端任务栏美化的全部功能、使用UPUPOO Windows客户端的所有音乐频谱、使用UPUPOO Windows客户端的所有鼠标指针、下载使用UPUPOO Windows客户端壁纸集市外的所有壁纸等。</p>
    <p class="protocal-title-bold">5 【激活码的安全】</p>
    <p>由于UPUPOO激活码是由后台系统自动生成的一串随机字母束自组合而成的序列号，具备唯一性和绑定关系，且符合一般虚拟商品的特征，所以一经购买或使用，不支持退货、换货、挂失、找回、解绑等服务。当收到验证码后，用户应妥善保存，避免被盗取从而导致损失。如因客观原因无法正常使用UPUPOO，用户可以在一天内联系客服申请退款并注明原因。</p>
    <p class="protocal-title-bold">6 【用户行为规范】</p>
    <p>用户在使用UPUPOO激活码时，除应遵守法律法规、本协议以及《UPUPOO用户协议》外，还应遵守以下规定：</p>
    <p>6.1 激活码仅供本人名义购买和使用；</p>
    <p>6.2 请勿泄露激活码；</p>
    <p>6.3 激活码不可转让、倒卖牟利。</p>
    <p>6.4 不得通过恶意手段获取和使用激活码，恶意情形包括但不限于：1）通过任何外挂软件或服务生成激活码；2）通过其他非UPUPOO明确授权许可的方式获取激活码的行为；3）采用非人工等方式访问攻击激活码获取的网络接口；4）采用非人工等方式访问攻击软件服务接口；5）获取激活码后从事违反法律法规的犯罪行为，如发布暴力、色情、血腥、反动等违法信息。</p>
    <p class="protocal-title-bold">7 【责任承担】</p>
    <p>7.1 您理解并同意，您应承担您UPUPOO激活码服务项下所有活动产生的全部责任。</p>
    <p>7.2 因UPUPOO原因导致您的激活码被盗，UPUPOO将依法承担相应责任。非因UPUPOO原因导致的，您应自行承担相关法律责任和损失。</p>
    <p>7.3 为免产生纠纷，您不得有偿或无偿转让UPUPOO激活码。否则，您应当自行承担由此产生的任何责任，同时UPUPOO保留追究上述行为人法律责任的权利。</p>
    <p class="protocal-title-bold">8 【激活码的使用限制】</p>
    <p>8.1 如您违反相关法律法规、本协议或其他相关协议、规则，UPUPOO有权限制、中止、冻结或终止您对UPUPOO账号的使用，且根据实际情况决定是否恢复您对该账号的使用。</p>
    <p>8.2 按照本协议、相关法律法规或其他相关协议，UPUPOO对您的账号采取限制、中止、冻结或终止使用等措施，而由此给您带来的损失，由您自行承担。</p>
    <p class="protocal-title-bold">9 【其他】</p>
    <p>9.1 您申请或使用UPUPOO激活码及相关服务即视为您已阅读并同意受本协议的约束。UPUPOO有权在必要时修改本协议条款，您可以官网upupoo.com上查阅本协议的最新版本。本协议条款变更后，如果您继续使用UPUPOO软件服务，即视为您已接受变更后的协议。</p>
    <p>9.2 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
    <p>9.3 若本协议有中文、英文等多个语言版本，相应内容不一致的，均以中文版的内容为准。（正文完）</p>
    <p>UPUPOO</p>
    <p>武汉小咪网络科技有限公司</p>
</div>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.filePage {
  background: #fff;
  width: 100%;
  height: 100%;

  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }

  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    min-height: 886px;
    color: #000;
    font-size: 16px;
  }

  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }

  .protocol-layout-body p {
    margin-bottom: 20px;
    line-height: 24px;
  }

  .protocal-title-bold {
    font-weight: bold;
  }
}
</style>
